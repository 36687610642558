exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-aceros-js": () => import("./../../../src/pages/aceros.js" /* webpackChunkName: "component---src-pages-aceros-js" */),
  "component---src-pages-conexiones-js": () => import("./../../../src/pages/conexiones.js" /* webpackChunkName: "component---src-pages-conexiones-js" */),
  "component---src-pages-contacto-js": () => import("./../../../src/pages/contacto.js" /* webpackChunkName: "component---src-pages-contacto-js" */),
  "component---src-pages-herramientas-biseladoras-js": () => import("./../../../src/pages/herramientas/biseladoras.js" /* webpackChunkName: "component---src-pages-herramientas-biseladoras-js" */),
  "component---src-pages-herramientas-corta-tubo-js": () => import("./../../../src/pages/herramientas/corta-tubo.js" /* webpackChunkName: "component---src-pages-herramientas-corta-tubo-js" */),
  "component---src-pages-herramientas-equipos-de-soldadura-js": () => import("./../../../src/pages/herramientas/equipos-de-soldadura.js" /* webpackChunkName: "component---src-pages-herramientas-equipos-de-soldadura-js" */),
  "component---src-pages-herramientas-expansores-js": () => import("./../../../src/pages/herramientas/expansores.js" /* webpackChunkName: "component---src-pages-herramientas-expansores-js" */),
  "component---src-pages-herramientas-index-js": () => import("./../../../src/pages/herramientas/index.js" /* webpackChunkName: "component---src-pages-herramientas-index-js" */),
  "component---src-pages-herramientas-insertos-js": () => import("./../../../src/pages/herramientas/insertos.js" /* webpackChunkName: "component---src-pages-herramientas-insertos-js" */),
  "component---src-pages-herramientas-roladoras-js": () => import("./../../../src/pages/herramientas/roladoras.js" /* webpackChunkName: "component---src-pages-herramientas-roladoras-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-tuberias-js": () => import("./../../../src/pages/tuberias.js" /* webpackChunkName: "component---src-pages-tuberias-js" */)
}

